const getBaseUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return process.env.PROD_BASE_URL; // Use production URL
  }
  if (
    window.location.protocol === "app:" ||
    window.location.protocol === "file:"
  ) {
    return process.env.DEV_BASE_URL; // Use Cordova URL (same as dev)
  }
  return process.env.DEV_BASE_URL; // Use development URL
};

// Export the base URL
const Config = {
  baseUrl: getBaseUrl(),
};

export default Config;
