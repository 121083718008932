import envConfig from "./envConfig.js";

const googleAuthUrl = `${envConfig.baseUrl}/auth/google`;
const facebookAuthUrl = `${envConfig.baseUrl}/auth/facebook`;
const privacyPolicyUrl = `${envConfig.baseUrl}/privacy-policy`;

// fetch(`${envConfig.baseUrl}/api/some-endpoint`)
//   .then((response) => response.json())
//   .then((data) => console.log(data));

document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady() {
  console.log("Cordova is ready");

  console.log("Using base URL:", envConfig.baseUrl);

  let googleLoginButton = document.getElementById("google-login-button");
  let facebookLoginButton = document.getElementById("facebook-login-button");
  let privacyPolicyButton = document.getElementById("privacy-policy-link");

  googleLoginButton.addEventListener("click", (event) => {
    handleAuthClick(event, googleAuthUrl);
  });

  facebookLoginButton.addEventListener("click", (event) => {
    handleAuthClick(event, facebookAuthUrl);
  });

  privacyPolicyButton.addEventListener("click", (event) => {
    handleAuthClick(event, privacyPolicyUrl);
  });

  // Click a Googel button to initiate login
  // googleLoginButton.addEventListener("click", loginWithGoogle);

  // // Click a Facebook button to initiate login
  // facebookLoginButton.addEventListener("click", loginWithFacebook);

  // privacyPolicyButton.addEventListener("click", clickPrivacyPolicyButton);

  // These authentications with SafariViewController work great but the window has 'Safari in-app browser toolbar'
  // Function to open Google OAuth URL
  function loginWithGoogle() {
    // Define your OAuth URL
    // var googleOAuthUrl = authUrl + "/auth/google";

    // Open the URL using SFSafariViewController
    SafariViewController.show(
      {
        url: googleAuthUrl,
        hidden: false,
        animated: true,
        transition: "curl",
        enterReaderModeIfAvailable: false,
        tintColor: "#00ff00",
      },
      function (result) {
        if (result.event === "opened") {
          console.log("Opened SFSafariViewController");
        } else if (result.event === "loaded") {
          console.log("Loaded URL in SFSafariViewController");
        } else if (result.event === "closed") {
          console.log("SFSafariViewController closed");
          //window.location.href = "room.html";
        }
      },
      function (msg) {
        console.error("Failed to open SFSafariViewController: " + msg);
      }
    );
  }

  // Function to open Facebook OAuth URL
  function loginWithFacebook() {
    // Define your OAuth URL
    //var facebookOAuthUrl = authUrl + "/auth/facebook";

    // Open the URL using SFSafariViewController
    SafariViewController.show(
      {
        url: facebookAuthUrl,
        hidden: false,
        animated: true,
        transition: "curl",
        enterReaderModeIfAvailable: false,
        tintColor: "#00ff00",
      },
      function (result) {
        if (result.event === "opened") {
          console.log("Opened SFSafariViewController");
        } else if (result.event === "loaded") {
          console.log("Loaded URL in SFSafariViewController");
        } else if (result.event === "closed") {
          console.log("SFSafariViewController closed");
        }
      },
      function (msg) {
        console.error("Failed to open SFSafariViewController: " + msg);
      }
    );
  }

  // function loginWithGoogle() {
  //   const options =
  //     "location=no,clearcache=yes,clearsessioncache=yes,toolbar=no,fullscreen=yes,hidenavigationbuttons=yes,hideurlbar=yes";

  //   const browser = cordova.InAppBrowser.open(
  //     authUrl + "/auth/google",
  //     "_blank",
  //     options
  //   );

  //   browser.addEventListener("loadstart", (event) => {
  //     const redirectUrl = event.url;

  //     if (redirectUrl.includes("auth/success")) {
  //       // Authentication succeeded; close the browser
  //       alert("Login successful!");
  //       browser.close();
  //     } else if (redirectUrl.includes("auth/failure")) {
  //       // Handle authentication failure
  //       alert("Authentication failed. Please try again.");
  //       browser.close();
  //     }
  //   });

  //   browser.addEventListener("loaderror", (event) => {
  //     alert(`Error loading page: ${event.message}`);
  //     browser.close();
  //   });
  // }

  // function loginWithFacebook() {
  //   const options =
  //     "location=no,clearcache=yes,clearsessioncache=yes,toolbar=no,fullscreen=yes,hidenavigationbuttons=yes,hideurlbar=yes";

  //   const browser = cordova.InAppBrowser.open(
  //     authUrl + "/auth/facebook",
  //     "_blank",
  //     options
  //   );

  //   browser.addEventListener("loadstart", (event) => {
  //     const redirectUrl = event.url;

  //     if (redirectUrl.includes("auth/success")) {
  //       // Authentication succeeded; close the browser
  //       alert("Login successful!");
  //       browser.close();
  //     } else if (redirectUrl.includes("auth/failure")) {
  //       // Handle authentication failure
  //       alert("Authentication failed. Please try again.");
  //       browser.close();
  //     }
  //   });

  //   browser.addEventListener("loaderror", (event) => {
  //     alert(`Error loading page: ${event.message}`);
  //     browser.close();
  //   });
  // }

  // function clickPrivacyPolicyButton() {
  //   const options =
  //     "location=no,clearcache=yes,clearsessioncache=yes,toolbar=no,fullscreen=yes,hidenavigationbuttons=yes,hideurlbar=yes";

  //   const browser = cordova.InAppBrowser.open(
  //     authUrl + "/privacy-policy",
  //     "_blank",
  //     options
  //   );

  //   browser.addEventListener("loadstart", (event) => {
  //     const url = event.url;

  //     if (url.includes("auth/success")) {
  //       // Authentication succeeded; close the browser
  //       browser.close();
  //     } else if (url.includes("auth/failure")) {
  //       // Handle authentication failure
  //       alert("Authentication failed. Please try again.");
  //       browser.close();
  //     }
  //   });

  //   browser.addEventListener("loaderror", (event) => {
  //     alert(`Error loading page: ${event.message}`);
  //     browser.close();
  //   });
  // }

  function clickPrivacyPolicyButton() {
    console.log("Clicked on 'Privacy Policy' Button");
    // var privacyPolicyUrl = privacyPolicyUrl;

    SafariViewController.show(
      {
        url: privacyPolicyUrl,
        hidden: false,
        animated: true,
        transition: "curl",
        enterReaderModeIfAvailable: false,
        tintColor: "#00ff00",
      },
      function (result) {
        if (result.event === "opened") {
          console.log("Opened SFSafariViewController");
        } else if (result.event === "loaded") {
          console.log("Loaded URL in SFSafariViewController");
        } else if (result.event === "closed") {
          console.log("SFSafariViewController closed");
        }
      },
      function (msg) {
        console.error("Failed to open SFSafariViewController: " + msg);
      }
    );
  }

  // window.addEventListener("orientationchange", checkOrientation, false);
  // checkOrientation(); // Also check the initial orientation

  // function checkOrientation() {
  //   // Use the screen.orientation API
  //   const orientation = screen.orientation.type;

  //   if (orientation.startsWith("portrait")) {
  //     // The device is in portrait
  //     console.log("Portrait");
  //     showOrientationAlert();
  //   } else if (orientation.startsWith("landscape")) {
  //     // The device is in landscape
  //     console.log("Landscape");
  //     hideOrientationAlert();
  //   }
  // }

  // function showOrientationAlert() {
  //   document.getElementById("rotateDeviceOverlay").style.display = "flex";
  // }

  // function hideOrientationAlert() {
  //   document.getElementById("rotateDeviceOverlay").style.display = "none";
  // }
}

// WORKS WELL FOR FACEBOOK AUT BUT NOT GOOGLE ("Access blocked:")
// function handleAuthClick(event, fullUrl) {
//   console.log("Handling auth click for URL:", fullUrl);

//   // Prevent default behavior and stop propagation
//   event.preventDefault();
//   event.stopPropagation();

//   // Check if running in Cordova or a browser
//   if (
//     window.location.protocol === "app:" ||
//     window.location.protocol === "file:"
//   ) {
//     console.log(
//       "Running in Cordova, opening authentication in InAppBrowser:",
//       fullUrl
//     );

//     // Open the authentication URL in the InAppBrowser
//     const options = "location=no,toolbar=no,fullscreen=yes";
//     const authWindow = cordova.InAppBrowser.open(fullUrl, "_blank", options);

//     // Handle when the authentication window is closed
//     authWindow.addEventListener("exit", function () {
//       console.log("Authentication process complete, closing the browser.");
//       // Now you can load the game in your web view
//       loadGameInWebView();
//     });
//   } else {
//     console.log("Running in a browser, redirecting to:", fullUrl);
//     // In the browser, just use window.location or window.open for a new tab/window
//     window.open(fullUrl, "_blank"); // Open in a new tab (browser)
//   }
// }

// function loadGameInWebView() {
//   // Assuming authentication is successful, load the game in the current window
//   window.location.href = "game.html"; // Redirect to game page or use WKWebView
// }

function handleAuthClick(event, fullUrl) {
  console.log("Handling auth click for URL:", fullUrl);

  // Prevent default behavior and stop propagation
  event.preventDefault();
  event.stopPropagation();

  // // Construct the full URL
  // const fullUrl = `${baseUrl}${path}`;

  if (
    window.location.protocol === "app:" ||
    window.location.protocol === "file:"
  ) {
    console.log(
      "Running in Cordova, opening in SafariViewController:",
      fullUrl
    );
    try {
      SafariViewController.isAvailable((available) => {
        if (available) {
          SafariViewController.show(
            {
              url: fullUrl,
              hidden: false, // Set to true if you want it to open in the background
              animated: true, // Animate the transition
              toolbarColor: "#ffffff", // Customize the toolbar color
              controlTintColor: "#000000", // Customize the control button color
            },
            (result) => {
              if (result.event === "opened") {
                console.log("SafariViewController opened successfully.");
              } else if (result.event === "loaded") {
                console.log("SafariViewController loaded the URL.");
              } else if (result.event === "closed") {
                console.log("SafariViewController closed.");
              }
            },
            (error) => {
              console.error("SafariViewController error:", error);
            }
          );
        } else {
          console.error(
            "SafariViewController is not available on this device."
          );
          // Fallback to InAppBrowser or show an error message
        }
      });
    } catch (error) {
      console.error("Error opening SafariViewController:", error);
    }
  } else {
    console.log("Running in a browser, redirecting to:", fullUrl);
    // Redirect in the browser
    window.location.href = fullUrl;
  }
}
